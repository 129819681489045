import React, { useState } from 'react';
import firebase from "gatsby-plugin-firebase";
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Astra from '../components/Images/Logo';
import EnamLima from '../components/Images/EnamLima'
import SatuIndonesia from '../components/Images/SatuIndonesia';
import { v4 as uuidv4 } from 'uuid';

function Submission (){
    const [teamName, setTeamName] = useState('');
    const [category, setCategory] = useState();
    const [institution, setInstitution] = useState('');
    const [title, setTitle] = useState('');
    const [leaderName, setLeaderName] = useState('');
    const [topic, setTopic] = useState('');
    const [linkVideo, setLinkVideo] = useState('');

    const [submissionEssay, setSubmissionEssay] = useState();
    const [submssionSlideDeck, setSubmssionSlideDeck] = useState();

    const [sending, setSending] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        
        let id = uuidv4();

        let obj = {
            teamName : teamName,
            category : category,
            institution : institution,
            title : title,
            leader : leaderName,
            topic : topic,
            link : linkVideo,
        }

        Promise.all([
            doUpload(id, submissionEssay, 'submissionEssay'),
            doUpload(id, submssionSlideDeck, 'submssionSlideDeck'),
            setDatabase(`/finalSubmission/${id}`, obj)
        ]).then(() => {
            setSending(false);
            setDone(true);
        }).catch(() => {
            setSending(false);
            setError(true);
        })

    };

    const onUploadEssay = (e) => {
        if (e.target.files[0] && e.target.files[0].size < 25000000) {
            setSubmissionEssay(e.target.files[0]);
        }
    };
    const onUploadSlideDeck = (e) => {
        if (e.target.files[0] && e.target.files[0].size < 25000000) {
            setSubmssionSlideDeck(e.target.files[0]);
        }
    };
    const setDatabase = (path, obj) => 
    firebase
      .database()
      .ref(path)
      .set(obj)

    const doUpload = (id, data, type) => new Promise((resolve, reject) => {
        const classRef = firebase.database().ref(`/finalSubmission/${id}`);
        const fileRef = firebase.storage().ref(`finalSubmission/${id}`);
        const fileData = fileRef.child(`${type}_${teamName}`);
        const uploadTask = fileData.put(data);
  
        uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        () => {
            // const uploadProgress =
            // (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setPorgressPercentage(uploadProgress);
        },
        () => { 
            //error
            reject();
        },
        async () => { 
            //complete
            try {
                const url = await fileData.getDownloadURL();
                await classRef.update({
                    [`${type}`] : url,
                });
                resolve();
  
            } catch (err) {
                reject();
            }
        }
        );
    });
    return (
        <Layout withRegister>
        <SEO title="InnovNation Submission" />
        <section id='hero' className="">
            <div
                className="py-10 md:py-20 text-white flex"
                style={{
                backgroundImage: `url("https://i.ibb.co/zPvHKnQ/Landing.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                minWidth: '100%'
                }}
            >
                <div className="container mx-auto px-8 lg:flex">
                <div className="text-center lg:text-left w-full">
                <div className="flex justify-between items-center">
                    <div className="w-32 lg:w-40 mb-8 lg:mb-10">
                        <Astra />
                    </div>
                    <div className="w-16 lg:w-24 mb-8 lg:mb-10">
                        <EnamLima />
                    </div>
                    <div className="w-24 lg:w-32 mb-8 lg:mb-10">
                        <SatuIndonesia />
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section id="title" className="">
          <div className="container mx-auto text-center flex items-center flex-col">
            <div className="text-center md:text-left w-full">
                <h1 className="text-2xl md:text-4xl font-bold text-center text-primary my-12">Final Submission</h1>
                <form onSubmit={(e) => handleSubmit(e)} className="w-full px-12 md:px-20">
                <div className="w-full my-4 md:my-8">
                    <div
                        className={
                        "flex flex-col md:flex-row lg:items-center"
                        }
                    >
                        <div className="flex items-center pr-4">
                            <input 
                                required 
                                type="radio" 
                                name="topic" 
                                checked={topic === "FutureOfMobility"} 
                                onChange={() => {
                                    setTopic('FutureOfMobility');
                                }}
                            />
                            <label className="pl-2 dark:text-grey-light">Future of Mobility</label>
                        </div>
                        <div className="flex items-center pr-4">
                            <input 
                                required 
                                type="radio" 
                                name="topic" 
                                checked={topic === "FutureOfEnergy"} 
                                onChange={() => {
                                    setTopic('FutureOfEnergy');
                                }}
                            />
                            <label className="pl-2 dark:text-grey-light">Future of Energy</label>
                        </div>
                    </div>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <p className="font-semibold md:text-lg pb-2 text-justify">
                            Nama Tim*
                        </p>
                        <div
                            className={
                            "flex flex-col lg:flex-row lg:items-center"
                            }
                        >
                            <input
                            type="text"
                            name="subject"
                            placeholder="Nama Tim"
                            className="w-full py-2 lg:py-4 border-grey-500 border-b-2 dark:text-grey focus:outline-none focus:border-black"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            required
                            />
                        </div>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <div
                            className={
                            "flex flex-col md:flex-row lg:items-center"
                            }
                        >
                            <div className="flex items-center pr-4">
                                <input 
                                    required 
                                    type="radio" 
                                    name="category" 
                                    checked={category === "SMA/SMK/Sederajat"} 
                                    onChange={() => {
                                            setCategory('SMA/SMK/Sederajat');
                                    }}
                                />
                                <label className="pl-2 dark:text-grey-light">SMA/SMK/Sederajat</label>
                            </div>
                            <div className="flex items-center pr-4">
                                <input 
                                    required 
                                    type="radio" 
                                    name="category" 
                                    checked={category === "Universitas"} 
                                    onChange={() => {
                                        setCategory('Universitas');
                                    }}
                                />
                                <label className="pl-2 dark:text-grey-light">Universitas</label>
                            </div>
                        </div>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <p className="font-semibold md:text-lg pb-2 text-justify">
                            Asal Institusi/Sekolah*
                        </p>
                        <div
                            className={
                            "flex flex-col lg:flex-row lg:items-center"
                            }
                        >
                            <input
                            type="text"
                            name="institution"
                            placeholder="Nama Institusi/Sekolah"
                            className="w-full py-2 lg:py-4 border-grey-500 border-b-2 dark:text-grey focus:outline-none focus:border-black"
                            value={institution}
                            onChange={(e) => setInstitution(e.target.value)}
                            required
                            />
                        </div>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <p className="font-semibold md:text-lg pb-2 text-justify">
                            Nama Ketua*
                        </p>
                        <div
                            className={
                            "flex flex-col lg:flex-row lg:items-center"
                            }
                        >
                            <input
                            type="text"
                            name="name_leader"
                            placeholder="Nama Ketua Tim"
                            className="w-full py-2 lg:py-4 border-grey-500 border-b-2 dark:text-grey focus:outline-none focus:border-black"
                            value={leaderName}
                            onChange={(e) => setLeaderName(e.target.value)}
                            required
                            />
                        </div>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <p className="font-semibold md:text-lg pb-2 text-justify">
                            Judul Karya*
                        </p>
                        <div
                            className={
                            "flex flex-col lg:flex-row lg:items-center"
                            }
                        >
                            <input
                            type="text"
                            name="TItle"
                            placeholder="Judul Karya"
                            className="w-full py-2 lg:py-4 border-grey-500 border-b-2 dark:text-grey focus:outline-none focus:border-black"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            />
                        </div>
                    </div>
                    <div className="flex gap-x-12 w-full ">
                        {submissionEssay ? (
                            <div className="w-full flex flex-col">
                                <p className='text-sm'>Submit Essay</p>
                                <div className='text-black px-4 py-2 rounded-lg border border-grey w-full'>
                                    <p className="text-sm">{submissionEssay.name}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex flex-col">
                                <p className='font-semibold md:text-lg pb-2 text-justify'>Submit Essay (max 17 Feb 2022, 23.59 WIB)</p>
                                <div className='text-black px-4 py-2 rounded-lg border border-grey w-full'>
                                    <p>Choose File</p>
                                </div>
                            </div>
                        )}
                        <label className="rounded-md items-center px-4 py-2 lg:py-0 lg:h-14 font-bold flex justify-center bg-primary hover:bg-blue-light col-span-1 text-white cursor-pointer whitespace-nowrap">
                        Upload
                        <input
                          type="file"
                          id="submissionEssay"
                          onChange={onUploadEssay}
                          accept="application/pdf"
                          className="hidden"
                          
                        />
                      </label>
                    </div>
                    <div className="flex gap-x-12 w-full mt-8">
                        {submssionSlideDeck ? (
                            <div className="w-full flex flex-col">
                                <p className='text-sm'>Submit Slide Deck</p>
                                <div className='text-black px-4 py-2 rounded-lg border border-grey w-full'>
                                    <p className="text-sm">{submssionSlideDeck.name}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex flex-col">
                                <p className='font-semibold md:text-lg pb-2 text-justify'>Submit Slide Deck (max 22 Feb 2022, 23.59 WIB)</p>
                                <div className='text-black px-4 py-2 rounded-lg border border-grey w-full'>
                                    <p>Choose File</p>
                                </div>
                            </div>
                        )}
                        <label className="rounded-md items-center px-4 py-2 lg:py-0 lg:h-14 font-bold flex justify-center bg-primary hover:bg-blue-light col-span-1 text-white cursor-pointer whitespace-nowrap">
                        Upload
                        <input
                          type="file"
                          id="submssionSlideDeck"
                          onChange={onUploadSlideDeck}
                          accept="application/pdf"
                          className="hidden"
                        />
                      </label>
                    </div>
                    <div className="w-full my-4 md:my-8">
                        <p className="font-semibold md:text-lg pb-2 text-justify">
                        Link Video & File Ilustrasi Prototype (max 22 Feb 2022, 23.59 WIB)
                        </p>
                        <div
                            className={
                            "flex flex-col lg:flex-row lg:items-center"
                            }
                        >
                            <input
                            type="text"
                            name="video"
                            placeholder="Link Video"
                            className="w-full py-2 lg:py-4 border-grey-500 border-b-2 dark:text-grey focus:outline-none focus:border-black"
                            value={linkVideo}
                            onChange={(e) => setLinkVideo(e.target.value)}
                            
                            />
                        </div>
                    </div>

                    <div className="mb-8 flex items-center flex-col">
                        <div className="flex items-center justify-center">
                            <div className="">
                            <input
                                type="submit"
                                disabled={sending}
                                className={
                                "bg-primary cursor-pointer hover:bg-primary-lighter font-bold text-white rounded-xl px-6 py-2"
                                }
                            ></input>
                            <div className="min-h-3.5vh flex justify-center">
                                {sending ? (
                                <div className="text-xs font-semibold text-center">
                                    <p>Sending..</p>
                                </div>
                                ) : done ? (
                                <p className="text-green font-semibold">
                                    Submitted
                                </p>
                                ) : error ? (
                                <p className="text-green font-semibold">
                                    Error
                                </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </section>
      </Layout>
    );
};

export default Submission;
